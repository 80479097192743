.card-section {
                    background-color: rgb(234, 234, 234);

}

.backside,
.frontside {
                    height: 345px;
}

.card-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;

                    background-color: rgb(234, 234, 234);
                    padding-top: 40px;
                    padding-bottom: 80px;
}

.backside,
.frontside {
                    height: 345px;
                    color: #fff;
}

.flip-card {
                    width: 300px;
                    height: 250px;
                    perspective: 1000px;
                    margin: 10px;
}

.flip-card-inner {
                    width: 100%;
                    height: 100%;
                    transition: transform 0.5s;
                    transform-style: preserve-3d;
                    position: relative;
}

.flip-card:hover .flip-card-inner {
                    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    backface-visibility: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
}

.flip-card-front {
                    background-color: white;
                    border-top-left-radius: 30px;
                    border-bottom-right-radius: 30px;
}

.flip-card-back {
                    background-color: #00bbf0;
                    transform: rotateY(180deg);
                    border-top-left-radius: 30px;
                    border-bottom-right-radius: 30px;
}


.donate-section {
                    position: relative;
                    /* Required for absolute positioning of pseudo-element */
                    background-image: url('../img/Donate.png');
                    background-size: cover;
                    background-position: center;
                    color: white;
                    padding: 100px 0;
                    background-attachment: fixed;
                    background-repeat: no-repeat;
                    /* Adjust as needed */
}

/* Pseudo-element for overlay */
.donate-section::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    /* Adjust the opacity (4th value) as needed */
}

.donate-content {
                    position: relative;
                    /* Ensure content stays above the pseudo-element */
                    z-index: 1;
                    /* Ensure content stays above the pseudo-element */
                    max-width: 800px;
                    margin: 0 auto;
}

.account-number {
                    margin-top: 20px;
}

.donatebtn {
                    background-color: #00bbf0;
}
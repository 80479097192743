.about_section {
  color: #ffffff;
}

.about_section .heading_container {
  margin-bottom: 45px;
  padding-top: 45px;
}

.Us {
  color: #00bbf0;
}

.about_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about_section .img-box img {
  max-width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.about_section .detail-box h3 {
  font-weight: bold;
}

.about_section .detail-box p {
  margin-top: 15px;
}

.about_section .detail-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  margin-top: 15px;
}

.about_section .detail-box a:hover {
  background-color: #007fa4;
}
/* Portifolia.css */

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.portfolio-button:hover {
  animation: shake 0.5s;
}

.portfolio-image {
  transition: filter 0.3s;
}

.portfolio-image:hover {
  filter: blur(3px);
  cursor: pointer;
}

.plus-sign {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

.portfolio-image:hover .plus-sign {
  display: block;
}

.lists {
  background-color: #00bbf0;
  color: #ffffff;
  border: #00bbf0;
  list-style: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.lists:hover {
  background-color: #180f4f;
  color: #ffffff;
  border: #180f4f;
  list-style: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.active {
  background-color: #180f4f;
  color: #ffffff;
  border: #180f4f;
  list-style: none;
  padding: 5px 15px;
  border-radius: 5px;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  -ms-overflow-style: none;

}

.scroll-container::-webkit-scrollbar {
  display: none;

}
.navbar {
  background-color: #fff;
  color: #000;
  z-index: 4;

}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 2rem;
}

.navbar-menu {
  display: flex;
  list-style-type: none;
}

.navbar-item {
  padding: 0.1rem 1rem;
}

@media (max-width: 768px) {
  .navbar-toggle {
    display: block;
  }

  .navbar-menu {
    display: flex;
    /* Change display to flex */
    flex-direction: column;
    top: 100%;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s ease;
    /* Add transition for smooth animation */
    max-height: 0;
    overflow: hidden;
  }

  .navbar-menu.open {
    max-height: 100vh;
  }

  .navbar-item {
    width: 100%;

  }
}

.donateBtn {
  background: #00bbf0;
  padding: 4px 20px;
  border: #00bbf0;
  color: #fff;
}

/* 
@keyframes zoomIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
} */
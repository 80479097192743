/* client section end  */

 .info_section {
  background-color: #180f4f;
  color: #ffffff;
  padding: 45px 0 15px 0;
}

.info_section h4 {
  font-weight: 600;
  margin-bottom: 20px;
}

.info_section .info_col {
  margin-bottom: 30px;
}

.info_section .info_contact .contact_link_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.info_section .info_contact .contact_link_box a {
  margin: 5px 0;
  color: #ffffff;
}

.info_section .info_contact .contact_link_box a i {
  margin-right: 5px;
}

.info_section .info_contact .contact_link_box a:hover {
  color: #00bbf0;
}

.info_section .info_social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

.info_section .info_social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  border-radius: 100%;
  margin-right: 10px;
  font-size: 24px;
}

.info_section .info_social a:hover {
  color: #00bbf0;
}

.info_section .info_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.info_section .info_links a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  color: #ffffff;
}

.info_section .info_links a:hover {
  color: #00bbf0;
}

.info_section form input {
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  width: 100%;
  height: 45px;
  color: #ffffff;
  outline: none;
}

.info_section form input::-webkit-input-placeholder {
  color: #ffffff;
}

.info_section form input:-ms-input-placeholder {
  color: #ffffff;
}

.info_section form input::-ms-input-placeholder {
  color: #ffffff;
}

.info_section form input::placeholder {
  color: #ffffff;
}

.info_section form button {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 10px 55px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  margin-top: 15px;
}

.info_section form button:hover {
  background-color: #007fa4;
}


.footer_section {
  position: relative;
  background-color: #00bbf0;
  color: #ffff;
  text-align: center;
}

.footer_section p {
  color: #00204a;
  padding: 25px 0;
  margin: 0;
}

.footer_section p a {
  color: inherit;
}


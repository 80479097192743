/* .Landingpage { */
/* Set up initial background image */
/* background-image: linear-gradient(rgba(10, 10, 61, 0.778), rgba(0, 0, 1, 0.367)), url("../img/homeImage.JPG");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  animation: changeBackground 16s infinite linear; */
/* Change background every 12 seconds */
/* } */

/* @keyframes changeBackground {

  0%,
  100% {
    background-image: linear-gradient(rgba(10, 10, 61, 0.778), rgba(0, 0, 1, 0.367)), url("../img/homeImage1.png");
  }

  33% {
    background-image: linear-gradient(rgba(10, 10, 61, 0.778), rgba(0, 0, 1, 0.367)), url("../img/homeImage2.png");
  }

  66% {
    background-image: linear-gradient(rgba(10, 10, 61, 0.778), rgba(0, 0, 1, 0.367)), url("../img/homeImage3.png");
  }
} */


.Landingpage {
  background-image: linear-gradient(rgba(10, 10, 61, 0.778),
      rgba(0, 0, 1, 0.367)),
    url("../img/homeImage.JPG");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .Landingpage {
    /* Reduce the height for smaller screens */
    height: 65vh;
    /* You can adjust this value as needed */
  }
}

.service_section {
  position: relative;
}

.about_section {
  color: #000;
}

.donate {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 4px 20px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  border-radius: 20px;
  margin-top: 0px;

  padding: 7px 14px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
}

.donate:hover {
  background-color: #007fa4;
}

.homebtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hometxt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.hometxt2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: smaller;
  letter-spacing: 4px;
}

@media (max-width: 768px) {
  .hometxt {
    flex-direction: column;
    text-align: center;
  }

  .hometxt2 {
    letter-spacing: 2px;
  }
}

.box {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px;

}

.box .img-box img {
  transition: transform 0.5s ease
}

.box:hover .img-box img {
  transform: scale(1.1);

}

.detail-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: start;
  opacity: 0;
  transition: opacity 0.5s ease;
  width: 100%;
}

.box:hover .detail-box {
  opacity: 100;
  color: #ffffff;
  background-color: #180f4f6f;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 30px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 30px;
  padding: 10px;

}

.hidden {
  visibility: visible;
}

.Samplesection {
  background-color: #F8F9FA;

}

/* .Sampletext {
  justify-content: center;
  align-items: center;

} */
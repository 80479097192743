/* Navbar.css */

.navbar {
  background-color: #1b252b;
  padding: 10px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.navbar-brand img {
  margin-right: 0px;
}

.navbar-toggler {
  border: solid 1px rgb(54, 42, 150);
}

.navbar-toggler-icon {
  background-color: #ffffff;
  color: rgb(54, 42, 150);
}

.navbar-nav .nav-link {
  color: #060505;
}

.navbar-nav .nav-link:hover {
  color: rgb(54, 42, 150);
  background-color: rgba(21, 13, 80, 0.411);



}


.submit {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: px 20px;
  background-color: #00bbf0;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  border-radius: 20px;
  margin-top: 0px;
}

.submit:hover {
  background-color: #007fa4;
}